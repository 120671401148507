import type { GeneratedDocument } from '@/types/graphql'
import type { TableProps } from 'antd-v5'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { Result, Table } from 'antd-v5'
import moment from 'moment'

import { GENERATED_DOCUMENTS_QUERY } from '@/components/Booking/General/DocumentGeneratorSection/schema'
import EditAction from '@/components/Booking/General/DocumentGeneratorSection/TableView/EditAction'
import { NoPermissionWrapper } from '@/components/TableView/Styled'
import { useBookingStore } from '@/store/booking'
import { hasPermissionError } from '@/utils/u'

interface TableViewProps {
  bookingUuid: string
}

const TableView = (props: TableViewProps) => {
  const { t } = useTranslation()

  const { bookingUuid } = props

  const setDocumentGeneratorTableRefetch = useBookingStore.use.setDocumentGeneratorTableRefetch()
  const resetDocumentGeneratorTableRefetch =
    useBookingStore.use.resetDocumentGeneratorTableRefetch()

  const { data, loading, error, refetch } = useQuery(GENERATED_DOCUMENTS_QUERY, {
    variables: {
      groups: [bookingUuid]
    }
  })

  useEffect(() => {
    setDocumentGeneratorTableRefetch(refetch)

    return () => {
      resetDocumentGeneratorTableRefetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: TableProps<GeneratedDocument>['columns'] = [
    {
      key: 'number',
      title: t('booking.documentGenerator.generatedDocumentsTableColumns.number'),
      dataIndex: 'number',
      render: value => value || '-'
    },
    {
      key: 'documentTemplate',
      title: t('booking.documentGenerator.generatedDocumentsTableColumns.documentTemplate'),
      dataIndex: ['documentTemplate', 'code']
    },
    {
      key: 'status',
      title: t('booking.documentGenerator.generatedDocumentsTableColumns.status'),
      dataIndex: 'status'
    },
    {
      key: 'createdAt',
      title: t('booking.documentGenerator.generatedDocumentsTableColumns.createdAt'),
      dataIndex: 'createdAt',
      render: value => moment(value).format('ll h:mm A')
    },
    {
      key: 'updatedAt',
      title: t('booking.documentGenerator.generatedDocumentsTableColumns.updatedAt'),
      dataIndex: 'updatedAt',
      render: value => moment(value).format('ll h:mm A')
    },
    {
      key: 'action',
      title: '',
      align: 'right',
      render: (text, record) => {
        return <EditAction generatedDocumentUuid={record.uuid} refetch={refetch} />
      }
    }
  ]

  const dataSource = data?.generatedDocuments?.rows || []
  const pageInfo = data?.generatedDocuments?.pageInfo || {}

  return (
    <>
      {error && hasPermissionError(error) && (
        <NoPermissionWrapper>{t('error.permission.general')}</NoPermissionWrapper>
      )}

      {error && <Result status="warning" title={t('common.errorOccurred')} />}

      {!loading && !error && (
        <Table
          size="small"
          columns={columns}
          loading={loading}
          dataSource={dataSource}
          scroll={{ x: 'max-content' }}
          rowKey={(record: any) => record.uuid}
          pagination={
            pageInfo?.count && pageInfo?.limit && pageInfo?.count > pageInfo?.limit
              ? {
                  total: pageInfo.count || 0,
                  pageSize: pageInfo.limit || 10,
                  current: (pageInfo.offset || 0) / pageInfo.limit + 1,
                  onChange: (page, pageSize) => {
                    refetch({
                      limit: pageSize,
                      offset: (page - 1) * (pageSize ?? 10)
                    })
                  }
                }
              : false
          }
        />
      )}
    </>
  )
}

export default TableView
