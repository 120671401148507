import type { Job, JobType } from '@/types/graphql'
import type { FormComponentProps } from 'antd/es/form'
import {
  DynamicFieldTag,
  DynamicFieldType,
  JobTypeRequiredFieldControl,
  TripFormat
} from '@/types/graphql'

import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'antd'
import { Flex, Input } from 'antd-v5'
import cloneDeep from 'lodash/cloneDeep'
import keyBy from 'lodash/keyBy'
import startCase from 'lodash/startCase'

import { hasDynamicFieldTag } from '@/components/Booking/DynamicField/helper'
import Trips from '@/components/Booking/NewBookingForm/TransportDetailsSubForm/JobsForm/JobForm/Trips'
import { defaultFormItemLayout } from '@/components/Manage/FormItemMapper'
import { FormMode } from '@/components/Manage/Shared/CrudType/Form'
import JobTypeSelector from '@/components/Select/JobTypeSelector'
import DynamicField from '@/components/Shared/DynamicFieldComponent'
import EnumSelector from '@/components/Shared/EnumSelector'
import { isValidContainerNumber } from '@/components/Transport/Utils/jobHelper'
import useJobTypes from '@/hooks/useJobTypes'
import { useBookingStore } from '@/store/booking'

const multiDropLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
}

export enum EditMode {
  TRIPS = 'trips',
  CREATE = 'create',
  DETAILS = 'details'
}

export type JobPreSubmitProps = Job & {
  key: string
  tripFormat: TripFormat
}

interface JobFormProps extends FormComponentProps {
  mode?: FormMode
  updateJobs?: any
  jobIndex: number
  editMode?: EditMode
  allJobs: JobPreSubmitProps[]
  currentJob: JobPreSubmitProps
  selectedCompany?: string
  isNewBooking?: boolean
}

const JobForm = (props: JobFormProps) => {
  const {
    form,
    allJobs,
    jobIndex,
    currentJob,
    updateJobs = () => {},
    mode = FormMode.Create,
    isNewBooking = false
  } = props
  const { getFieldDecorator } = form

  const [isMounted, setIsMounted] = useState(false)
  const [dynamicFields, setDynamicFields] = useState<any>([])
  const [tripsRenderCount, setTripsRenderCount] = useState(0)
  const [jobType, setJobType] = useState<JobType | null>(null)
  const [requiredFields, setRequiredFields] = useState<any>([])
  const [tripFormat, setTripFormat] = useState<TripFormat>(currentJob?.tripFormat)
  const [containerNoFontColor, setContainerNoFontColor] = useState<string>('black')

  const { jobTypes, requestJobTypes } = useJobTypes()

  const setTripDynamicFields = useBookingStore.use.setTripDynamicFields()
  const selectedBillToCompany = useBookingStore.use.selectedBillToCompany()
  const resetTripDynamicFields = useBookingStore.use.resetTripDynamicFields()
  const selectedBookingTypeCode = useBookingStore.use.selectedBookingTypeCode()

  const getInitialFontColor = (val: string) => {
    if (!val) return 'black'
    if (isValidContainerNumber(val)) return 'green'
    if (val.length > 11) return 'red'
    return '#DAA520'
  }

  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])

  useEffect(() => {
    return () => {
      resetTripDynamicFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setJobType(jobTypes?.[0])
  }, [jobTypes])

  useEffect(() => {
    if (dynamicFields.length > 0) {
      dynamicFields.forEach(field => {
        if (hasDynamicFieldTag(field, DynamicFieldTag.ValidateContainerNumber)) {
          const value = currentJob?.details?.[field.key]
          if (value) {
            setContainerNoFontColor(getInitialFontColor(value))
          }
        }
      })
    }
  }, [dynamicFields, currentJob])

  useEffect(() => {
    if (!jobType) return

    const { trips } = form.getFieldsValue()

    const tripsToPopulate = jobType?.tripDetails?.length
      ? jobType?.tripDetails?.map((t: any, i: number) => trips?.[i] || {})
      : trips || [{}]
    form.setFieldsValue({
      trips: tripsToPopulate,
      type: jobType.code
    })

    setRequiredFields(keyBy(jobType.requiredFields, 'name'))
    setDynamicFields(jobType.dynamicFields || [])
    setTripDynamicFields(jobType.tripDynamicFields || [])

    if (!jobType.tripDetails?.length) return

    const currentTrips = form.getFieldValue('trips') || []

    const tripDetailsLength = jobType.tripDetails.length

    if (currentTrips.length > tripDetailsLength) {
      const diff = currentTrips.length - tripDetailsLength
      currentTrips.length -= diff

      form.setFieldsValue({ trips: currentTrips })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobType])

  const handleJobChange = useCallback(() => {
    const currentJobValues = form.getFieldsValue()

    if (
      (currentJobValues.type && !jobTypes) ||
      !jobTypes.find((jt: JobType) => jt.code === currentJobValues.type)
    ) {
      requestJobTypes({ codes: [currentJobValues.type] })
    }

    if (currentJobValues.tripFormat) {
      setTripFormat(currentJobValues.tripFormat)
    }

    allJobs[jobIndex] = { ...currentJob, ...currentJobValues }

    updateJobs?.(allJobs)

    const timeoutId = setTimeout(() => {
      if (isMounted) setTripsRenderCount(prev => prev + 1)
    }, 200)

    return () => {
      clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allJobs, form, jobIndex])

  const handelTripsChange = trips => {
    const newTrips = cloneDeep(trips)
    currentJob.trips = newTrips
    form.setFieldsValue({ trips: newTrips })
    handleJobChange()
  }

  useEffect(() => {
    handleJobChange()
  }, [handleJobChange, form])

  const initialTripFormat = useMemo(() => {
    return isNewBooking ? jobType?.defaultTripFormat : currentJob?.tripFormat
  }, [currentJob, jobType, isNewBooking])

  return (
    <Flex vertical>
      <Flex gap="small">
        <Flex flex={0.5}>
          <Form.Item label="Job Type" {...defaultFormItemLayout} className="w-full">
            {getFieldDecorator('type', {
              initialValue: currentJob?.type,
              rules: [{ required: true, message: 'Job type is required.' }]
            })(
              <JobTypeSelector
                defaultActiveFirstOption
                queryVariables={{ byCompany: selectedBillToCompany }}
                bookingTypeCode={selectedBookingTypeCode}
              />
            )}
          </Form.Item>
        </Flex>
        <Flex flex={0.5}>
          <Form.Item label="Trip Order Format" {...defaultFormItemLayout} className="w-full">
            {getFieldDecorator('tripFormat', {
              initialValue: initialTripFormat || TripFormat.None
            })(
              <EnumSelector
                camelCaseToNormal
                enumName="TripFormat"
                placeholder="Select Trip Format"
              />
            )}
          </Form.Item>
        </Flex>
      </Flex>

      {dynamicFields.length > 0 ? (
        <Flex vertical>
          {dynamicFields.map((field: any) => {
            if (hasDynamicFieldTag(field, DynamicFieldTag.HideFromNewBooking)) {
              return null
            }
            let dynamicFieldRules = [
              {
                required: field.control === JobTypeRequiredFieldControl.Required,
                message: `${field.key} is required`
              }
            ]

            if (hasDynamicFieldTag(field, DynamicFieldTag.ValidateContainerNumber)) {
              dynamicFieldRules = [
                ...dynamicFieldRules,
                {
                  // @ts-expect-error
                  validator: (_: unknown, val: string) => {
                    return setContainerNoFontColor(getInitialFontColor(val))
                  }
                }
              ]
            }
            return (
              <Form.Item key={field.key} label={startCase(field.key)} {...multiDropLayout}>
                {getFieldDecorator(`details.${field.key}`, {
                  initialValue: currentJob?.details?.[field.key],
                  rules: dynamicFieldRules
                })(
                  <DynamicField
                    id={field.key}
                    field={field}
                    textColor={
                      hasDynamicFieldTag(field, DynamicFieldTag.ValidateContainerNumber)
                        ? containerNoFontColor
                        : undefined
                    }
                    disabled={mode === FormMode.Edit && field.type === DynamicFieldType.Date}
                  />
                )}
              </Form.Item>
            )
          })}
        </Flex>
      ) : null}

      <Form.Item label="Remarks" {...multiDropLayout} style={{}}>
        {getFieldDecorator('remarks', { initialValue: currentJob?.remarks })(
          <Input autoComplete="off" placeholder="Enter job remarks..." />
        )}
      </Form.Item>

      <Form.Item label=" " colon={false} {...multiDropLayout}>
        {getFieldDecorator('trips', { initialValue: currentJob?.trips || [{}] })(
          <Trips
            jobUuid={currentJob?.uuid}
            jobType={jobType || {}}
            onChange={handelTripsChange}
            requiredFields={requiredFields}
            format={tripFormat || currentJob?.tripFormat}
            tripsRenderCount={tripsRenderCount}
            mode={mode}
          />
        )}
      </Form.Item>
    </Flex>
  )
}

export default Form.create<JobFormProps>()(memo(JobForm))
