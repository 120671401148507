import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd-v5'

import AddAction from '@/components/Booking/General/DocumentGeneratorSection/AddAction'
import TableView from '@/components/Booking/General/DocumentGeneratorSection/TableView'
import Section from '@/components/Booking/Section'
import { useBookingStore } from '@/store/booking'

interface DocumentGeneratorSectionProps {
  bookingUuid: string
}

const DocumentGeneratorSection = ({ bookingUuid }: DocumentGeneratorSectionProps) => {
  const { t } = useTranslation()

  const setBookingUuidForDocumentGenerator =
    useBookingStore.use.setBookingUuidForDocumentGenerator()
  const resetBookingUuidForDocumentGenerator =
    useBookingStore.use.resetBookingUuidForDocumentGenerator()

  useEffect(() => {
    setBookingUuidForDocumentGenerator(bookingUuid)

    return () => {
      resetBookingUuidForDocumentGenerator()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Title = (
    <Typography.Text>
      {t('booking.documentGenerator.sectionTitle')} <AddAction />
    </Typography.Text>
  )

  return (
    <Section title={Title}>
      <TableView bookingUuid={bookingUuid} />
    </Section>
  )
}

export default DocumentGeneratorSection
