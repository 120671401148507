import { PureComponent } from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { Button, Popconfirm } from 'antd'
import PropTypes from 'prop-types'

import { JOBS_FROM_BOOKING_SLIM_QUERY } from 'App/containers/booking/schema/basicBookingQuerySlim'
import { UNDELETE_JOB } from 'App/graphql/booking'
import respHandler from 'App/utils/responseHandler'

class JobUndeleteAction extends PureComponent {
  handleConfirm = () => async () => {
    const {
      undeleteJob,
      job: { uuid }
    } = this.props
    respHandler('undeleting job, hang on...', 'load')
    try {
      await undeleteJob(uuid)
      respHandler('Job undeleted successfully.', 'success')
    } catch (error) {
      respHandler(error, 'error')
    }
  }

  render() {
    return (
      <Popconfirm
        title="Confirm to undelete this job?"
        onConfirm={this.handleConfirm()}
        okText="Yes"
        okType="danger"
        cancelText="No"
      >
        <Button type="danger">Undelete</Button>
      </Popconfirm>
    )
  }
}

JobUndeleteAction.propTypes = {
  job: PropTypes.object.isRequired,
  undeleteJob: PropTypes.func
}

export default graphql(UNDELETE_JOB, {
  props: ({ mutate, ownProps }) => ({
    undeleteJob: uuid =>
      mutate({
        variables: { uuid },
        update: cache => {
          const data = cache.readQuery({
            query: JOBS_FROM_BOOKING_SLIM_QUERY,
            variables: { uuid: ownProps.job.bookingUuid }
          })

          if (!data || !data.booking) return
          const { booking } = data.booking

          cache.writeQuery({
            query: JOBS_FROM_BOOKING_SLIM_QUERY,
            variables: { uuid: ownProps.job.bookingUuid },
            data: { booking }
          })
        },
        refetchQueries: [
          {
            query: JOBS_FROM_BOOKING_SLIM_QUERY,
            variables: {
              uuid: ownProps.job.bookingUuid
            }
          }
        ]
      })
  })
})(JobUndeleteAction)
