import type { DynamicTransportSelectProps } from '@/components/Select/DynamicSelector'
import type { BookingType, JobType } from '@/types/graphql'

import { forwardRef, useCallback, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { isEmpty } from 'lodash'

import { VIEW_BOOKING_TYPE_GQL } from '@/components/Booking/General'
import DynamicTransportSelect from '@/components/Select/DynamicSelector'
import { JOB_TYPES_QUERY } from '@/graphql/booking'

interface JobTypeSelectorProps extends Partial<DynamicTransportSelectProps> {
  bookingTypeCode?: string
  bookingTypeUuid?: string
}

const JobTypeSelector = forwardRef<HTMLDivElement, JobTypeSelectorProps>(({ ...props }, ref) => {
  const [bookingType, setBookingType] = useState<BookingType>({})

  const [getBookingType, { data }] = useLazyQuery(VIEW_BOOKING_TYPE_GQL, {
    fetchPolicy: 'cache-first'
  })

  const filterFunc = useCallback(
    (jobType: JobType) => {
      return bookingType?.allowedJobTypes?.length
        ? bookingType?.allowedJobTypes?.includes(jobType.code as string)
        : true
    },
    [bookingType?.allowedJobTypes]
  )

  useEffect(() => {
    if (!props.bookingTypeCode && !props.bookingTypeUuid) return

    const variables = {
      ...(props.bookingTypeCode && { code: props.bookingTypeCode }),
      ...(props.bookingTypeUuid && { uuid: props.bookingTypeUuid })
    }

    getBookingType({
      variables
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bookingTypeCode, props.bookingTypeUuid])

  useEffect(() => {
    if (isEmpty(data) || !data.bookingType) return
    setBookingType(data?.bookingType)
  }, [data])

  return (
    <DynamicTransportSelect
      {...props}
      ref={ref}
      query={JOB_TYPES_QUERY}
      filterFunc={filterFunc}
      type={'jobTypes'}
      searchText={'Select job type...'}
    />
  )
})

export default JobTypeSelector
