import type { JobType, QueryJobTypesArgs } from 'App/types/graphql'
import { JobTypeStatus } from 'App/types/graphql'

import { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { compact } from 'lodash'

import { logger } from 'App/utils/logger'

const JOB_TYPES_QUERY = gql`
  query useJobTypes(
    $codes: [String]
    $statuses: [JobTypeStatus]
    $q: String
    $byCompany: UUID
    $limit: Int
    $offset: Int
  ) {
    jobTypes(
      codes: $codes
      statuses: $statuses
      q: $q
      byCompany: $byCompany
      limit: $limit
      offset: $offset
    ) {
      rows {
        uuid
        code
        name
        defaultTripFormat
        tripDetails {
          type
          fromCompanyTypes
          toCompanyTypes
        }
        requiredFields {
          name
          control
        }
        unitOptions {
          units
          values
        }
        dynamicFields {
          key
          type
          query
          companyTypes
          control
          enumName
          customValues
          tags
        }
        tripDynamicFields {
          key
          type
          query
          companyTypes
          control
          enumName
          customValues
          tags
        }
      }
    }
  }
`

const useJobTypes = () => {
  const [jobTypes, setJobTypes] = useState<JobType[]>([])

  const [getJobTypes, { data, loading, error }] = useLazyQuery(JOB_TYPES_QUERY, {
    fetchPolicy: 'cache-first'
  })

  useEffect(() => {
    if (data?.jobTypes?.rows) setJobTypes(data.jobTypes.rows)
  }, [data])

  if (error) {
    logger.error('useJobTypes Error.', error)
  }

  const requestJobTypes = (input: QueryJobTypesArgs = {}) => {
    input.statuses = input?.statuses?.length ? input.statuses : [JobTypeStatus.Active]
    input.codes = compact(input.codes)

    getJobTypes({
      variables: input
    })
  }

  return {
    requestJobTypes,
    loading,
    error,
    jobTypes
  }
}

export default useJobTypes
