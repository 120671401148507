import type { JobType, Trip as TripType } from '@/types/graphql'
import { TripFormat } from '@/types/graphql'

import { Fragment, memo } from 'react'
import { v4 as uuidV4 } from 'uuid'

import Trip from '@/components/Booking/NewBookingForm/TransportDetailsSubForm/JobsForm/JobForm/Trip'
import { FormMode } from '@/components/Manage/Shared/CrudType/Form'

interface TripsProps {
  value?: any
  onChange: any
  jobUuid: string
  jobType: JobType
  requiredFields: any
  format: TripFormat
  tripsRenderCount?: number
  mode: FormMode
}

const Trips = (props: TripsProps) => {
  const { mode, value, format, jobType, jobUuid, onChange = () => {}, requiredFields } = props

  const onChangeTrip = (trips: TripType[]) => onChange(trips)

  return (
    <Fragment>
      {value?.map((t: any, index: number) => {
        return (
          <Trip
            key={`trip:${t.key || t.uuid || uuidV4()}`}
            mode={mode}
            jobUuid={jobUuid}
            trips={value}
            index={index}
            format={format}
            jobType={jobType}
            onChange={onChangeTrip}
            requiredFields={requiredFields}
            numberOfTrips={jobType?.tripDetails?.length || 0}
            tripDetail={jobType?.tripDetails?.[index] || undefined}
          />
        )
      })}
    </Fragment>
  )
}

export default memo(Trips)
