import type { Incentive, TransportJob } from '@/types/graphql'
import type { WrappedFormUtils } from 'antd/lib/form/Form'

import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Form } from 'antd'
import { Alert, Button, Col, Row } from 'antd-v5'

import { ActionItem, Actions } from '@/components/Manage/Styled'
import { extractLegUpdateFormValues } from '@/components/Transport/Components/Modals/JobActivityModal/helper'
import JobContainerSealDisplay from '@/components/Transport/Components/Modals/JobActivityModal/JobContainerSealDisplay'
import LegDetailsDisplay from '@/components/Transport/Components/Modals/JobActivityModal/LegDetailsDisplay'
import LegFormDetails from '@/components/Transport/Components/Modals/JobActivityModal/LegFormDetails'
import LegTimingsWrapper from '@/components/Transport/Components/Modals/JobActivityModal/LegTimingsWrapper'
import { INCENTIVES_QUERY } from '@/components/Transport/Components/Modals/JobActivityModal/schema'
import {
  UPDATE_LEG_MUTATION,
  UPDATE_LEG_TIMING_MUTATION
} from '@/components/Transport/Schemas/schema'
import { isActiveLeg } from '@/components/Transport/Utils/jobHelper'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'
import styles from './UpdateLegForm.module.css'

interface UpdateLegFormProps {
  form: WrappedFormUtils
  isVDJM?: boolean
  leg?: TransportJob
  legs: TransportJob[]
  onCloseForm: any
  legsLoading?: boolean
  refetchLegs: any
}

const UpdateLegForm = (props: UpdateLegFormProps) => {
  const { form, isVDJM, leg, legs, onCloseForm, legsLoading, refetchLegs } = props

  const { t } = useTranslation()

  const isDeleted: boolean = useMemo(() => !isActiveLeg(leg), [leg])

  const [editLegMode, setEditLegMode] = useState(
    !(isDeleted || (leg?.driverName && leg?.vehicleName))
  )
  const [incentives, setIncentives] = useState<Incentive[]>([])
  const [formSuccessMsg, setFormSuccessMsg] = useState('')
  const [formError, setFormError] = useState('')

  const onSwitchEditMode = useCallback(() => {
    setEditLegMode((state: boolean) => !state)
    setFormSuccessMsg('')
    setFormError('')
  }, [])

  const [updateLegTiming, { loading: updateLoading }] = useMutation(UPDATE_LEG_TIMING_MUTATION)

  const [updateLegMain, { loading }] = useMutation(UPDATE_LEG_MUTATION)

  const [getIncentives, { loading: incentiveLoading }] = useLazyQuery(INCENTIVES_QUERY, {
    onCompleted: data => {
      setIncentives(data?.incentives?.rows as Incentive[])
    },
    fetchPolicy: 'cache-first'
  })

  const onUpdateLeg = useCallback(() => {
    form.validateFields(async (err: any, values: any) => {
      if (err) return

      if (isDeleted) {
        return setFormError(`Leg ${leg?.sequence} is ${leg?.legStatus} and cannot be updated.`)
      }

      try {
        setFormSuccessMsg('')
        setFormError('')

        const { updateLegInput, updateLegTimingInput } = extractLegUpdateFormValues(leg, values)

        setFormSuccessMsg('Saving leg details...')

        if (updateLegInput.legUuid && updateLegMain) {
          const res = await updateLegMain({ variables: { input: updateLegInput } })
          if (res?.data?.updateLeg?.success) {
            responseHandler('Successfully updated leg.', 'success')
            onSwitchEditMode()

            const resUpdateLegTiming = await updateLegTiming({
              variables: { input: updateLegTimingInput }
            })
            if (resUpdateLegTiming?.data?.updateLegTiming?.success) {
              responseHandler('Successfully update planStart.', 'success')
              refetchLegs()
            }
          }
        } else {
          setFormSuccessMsg('')
          responseHandler('No leg selected for update.', 'warning')
        }
      } catch (error: any) {
        responseHandler(error, 'error')
        logger.error('UpdateLegForm updateLegMain error', error)
        setFormSuccessMsg('')
        setFormError(error.message || JSON.stringify(error))
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, leg, updateLegMain, updateLegTiming, refetchLegs])

  const setFieldsFromPreviousLeg = useCallback(
    (fields: string[]) => {
      if (!fields.length || !legs || legs?.length <= 1) return

      const currentLegIndex = legs.findIndex(l => l.uuid === leg?.uuid)

      if (currentLegIndex <= 0) return

      const previousLeg = legs[currentLegIndex - 1]

      fields.forEach((field: string) => {
        form.setFieldsValue({ [field]: previousLeg[field] })
      })
    },
    [legs, leg, form]
  )

  useEffect(() => {
    if (leg?.driverUuid || (leg?.driverName && leg?.driverCode)) return
    setFieldsFromPreviousLeg(['driverUuid', 'driverCode', 'driverName'])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leg, legs])

  useEffect(() => {
    if (isDeleted || (leg?.driverName && leg?.vehicleName)) {
      setEditLegMode(false)
    } else {
      setEditLegMode(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leg])

  useEffect(() => {
    if (!legs || !legs.length) return

    getIncentives({
      variables: { input: { legUuids: legs.map(leg => leg.legUuid) } }
    })
  }, [getIncentives, legs])

  return (
    <div className={!isVDJM ? styles.container : styles.vdjmContainer}>
      <Row>
        <Col span={8}>
          <JobContainerSealDisplay
            form={form}
            leg={leg}
            legsLoading={legsLoading}
            incentives={incentives || []}
            incentiveLoading={incentiveLoading}
          />
        </Col>
        <Col span={8}>
          {editLegMode ? (
            <LegFormDetails
              leg={leg}
              form={form}
              isVDJM={isVDJM}
              setFormError={setFormError}
              setFormSuccessMsg={setFormSuccessMsg}
            />
          ) : (
            <LegDetailsDisplay leg={leg} />
          )}

          <Row gutter={24}>
            <Col span={5} />
            <Col span={19}>
              {formSuccessMsg && (
                <Alert message={formSuccessMsg} type="success" style={{ fontSize: 12 }} />
              )}
              {formError && <Alert message={formError} type="error" style={{ fontSize: 12 }} />}
            </Col>
          </Row>

          <Actions>
            <ActionItem span={24}>
              <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  id="edit-cancel-button-leg-form"
                  disabled={isDeleted}
                  loading={legsLoading}
                  onClick={onSwitchEditMode}
                >
                  {editLegMode ? 'Cancel' : 'Edit'}
                  {!editLegMode && <EditOutlined />}
                </Button>

                {editLegMode && (
                  <Button
                    id="edit-leg-form-submit-button"
                    onClick={onUpdateLeg}
                    loading={loading || legsLoading || updateLoading}
                  >
                    {t('common.submit')} <CheckOutlined />
                  </Button>
                )}
              </Row>
            </ActionItem>
          </Actions>
        </Col>

        <Col span={8}>
          <LegTimingsWrapper
            leg={leg}
            form={form}
            legs={legs}
            legsLoading={legsLoading}
            onCloseForm={onCloseForm}
            refetchLegs={refetchLegs}
            setFormError={setFormError}
            setFormSuccessMsg={setFormSuccessMsg}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Form.create<UpdateLegFormProps>()(memo(UpdateLegForm))
