import type { GeneratedDocumentQuery } from '@/types/graphql'
import type { MenuProps } from 'antd-v5'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ExportOutlined, MenuOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Button, Divider, Dropdown, Flex, Modal, Spin, Typography } from 'antd-v5'
import isEmpty from 'lodash/isEmpty'

import { GENERATED_DOCUMENT_QUERY } from '@/components/Booking/General/DocumentGeneratorSection/schema'
import DocumentCreatorModalContent from '@/components/Booking/General/DocumentGeneratorSection/TableView/EditAction/DocumentCreatorModal/ModalContent'
import ModalFooter from '@/components/Booking/General/DocumentGeneratorSection/TableView/EditAction/DocumentCreatorModal/ModalFooter'
import config from '@/config'
import { useBookingStore } from '@/store/booking'
import responseHandler from '@/utils/responseHandler'

interface DocumentCreatorModalProps {
  afterClose?: () => void
  generatedDocumentUuid: string
}

const DocumentCreatorModal = ({
  afterClose = () => {},
  generatedDocumentUuid
}: DocumentCreatorModalProps) => {
  const { t } = useTranslation()

  const [visible, setVisible] = useState(true)

  const [generatedDocument, setGeneratedDocument] =
    useState<GeneratedDocumentQuery['generatedDocument']>()
  const setDocumentGeneratorModalRefetch = useBookingStore.use.setDocumentGeneratorModalRefetch()
  const resetDocumentGeneratorModalRefetch =
    useBookingStore.use.resetDocumentGeneratorModalRefetch()

  const { data, loading, error, refetch } = useQuery(GENERATED_DOCUMENT_QUERY, {
    variables: {
      uuid: generatedDocumentUuid
    }
  })

  useEffect(() => {
    if (!generatedDocument) setGeneratedDocument(data?.generatedDocument)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    setDocumentGeneratorModalRefetch(refetch)

    return () => {
      resetDocumentGeneratorModalRefetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = async () => {
    try {
      const { data } = await refetch()
      setGeneratedDocument(data?.generatedDocument)
    } catch (error) {
      responseHandler(error, 'error')
    }
  }

  const menuItems: MenuProps['items'] = [
    {
      key: 'printROT',
      label: (
        <Button
          type="link"
          size="small"
          target="_blank"
          href={`${config.documentGenerator.baseUrl}/preview?document=${generatedDocument?.uuid}`}
        >
          Print ROT
          <ExportOutlined />
        </Button>
      )
    }
  ]

  const Title = (
    <>
      <Flex align="center" justify="space-between" style={{ width: '97%' }}>
        <Typography.Text strong>{t('booking.documentGenerator.generic')}</Typography.Text>
        <Dropdown menu={{ items: menuItems }} placement="bottomRight">
          <MenuOutlined className="cursor-pointer" style={{ color: '#858585' }} />
        </Dropdown>
      </Flex>
      <Divider />
    </>
  )

  const Footer = (
    <Spin spinning={loading}>
      <ModalFooter
        generatedDocument={generatedDocument}
        onChange={handleChange}
        onClose={() => setVisible(false)}
      />
    </Spin>
  )

  return (
    <Modal
      centered
      destroyOnClose
      width="100%"
      open={visible}
      title={Title}
      footer={Footer}
      afterClose={afterClose}
      onCancel={() => setVisible(false)}
    >
      {error && <span>{t('common.errorOccurred')}</span>}

      {loading && <span>{t('common.loading')}</span>}

      {!error && !isEmpty(generatedDocument) && (
        <DocumentCreatorModalContent generatedDocument={generatedDocument} />
      )}
    </Modal>
  )
}

export default DocumentCreatorModal
