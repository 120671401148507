import type { Booking, Job, Maybe, User } from '@/types/graphql'
import type { TableProps } from 'antd-v5'
import type { ColumnProps } from 'antd/lib/table'
import { JobStatus } from '@/types/graphql'

import { Tooltip } from 'antd-v5'
import get from 'lodash/get'
import moment from 'moment'

import BookingStatusIcon from '@/components/Booking/ListView/BookingStatusIcon'
import UserIcon from '@/components/Booking/ListView/UserIcon'
import { getActTime } from '@/components/RelativeDateTime'
import config from '@/config'
import { getContainerStats } from '@/utils/booking'

export type BookingTableColumnProps = ColumnProps<Booking> & {
  fnc?: string
  convert?: Record<string, string>
  onCell?: (booking: Booking) => object
}

const renderWithTooltip = (text?: string) => {
  return (
    <Tooltip title={text} mouseEnterDelay={0.1}>
      {text}
    </Tooltip>
  )
}

export const getShortCntr = (record: Booking) => {
  const activeJobs = record?.jobs?.filter((j: Maybe<Job>) => j?.status !== JobStatus.Cancelled)
  let containerStats = getContainerStats(activeJobs)
    .map(s => `${s.count}x${s.size} ${s.type ? s.type : ''}`)
    .join(', ')
  containerStats = (record.type || record.details.shipmentType || '') + ' ' + containerStats
  return containerStats
}

export const getFormattedDateBookingList = (record: Booking, key: string) => {
  const data = get(record, key)
  return data ? moment(data).format('DD/MM/YY') : '-'
}

export const getNumJobTrip = (record: Record<string, unknown>) => {
  return Array.isArray(record.jobs) ? record.jobs.length : 0
}

const functions = {
  renderTooltip: (text: string) => renderWithTooltip(text),
  shortCntr: (record: Booking) => {
    const containerStats = getShortCntr(record)
    return renderWithTooltip(containerStats)
  },
  jobRequestType: (text: string, record: Booking) => {
    return [record.no, record.details.requestNo, record.type].filter(Boolean).join(' ')
  },
  containerDetails: (_, record: Booking) => {
    const jd = record.jobs?.[0]?.details
    const trailerDetails = jd.trailerType === 'NORMAL' ? '' : ` [${jd.trailerType}]`
    return `${jd.containerNo} (${jd.containerSize}${jd.containerType})${trailerDetails}`
  },
  etaEtd: (_, record: Booking) => {
    const eta = getFormattedDateBookingList(record, 'estPick')
    const etd = getFormattedDateBookingList(record, 'estDrop')
    return renderWithTooltip(`${eta} / ${etd}`)
  },
  date: (_, record) => {
    const dateCreated = getFormattedDateBookingList(record, 'createdAt')
    return renderWithTooltip(dateCreated)
  },
  bookingStatus: (_, record: Booking) => {
    return (
      <>
        <BookingStatusIcon booking={record} />
        {record.assignees?.map(u => <UserIcon key={u?.user?.uuid} user={u?.user as User} />)}
      </>
    )
  },
  bookingStatusHlg: (text: string, record: Booking) => {
    const s = record.lStat
    return (
      <>
        <BookingStatusIcon booking={record} />
        <span style={{ marginLeft: '5px' }}>{s ? `${s.t} ${getActTime(s.ts)}` : ''}</span>
      </>
    )
  },
  stringArray: (text: string[]) => (Array.isArray(text) ? text.join(', ') : '')
}

const defaultCols: TableProps<Booking>['columns'] = [
  {
    key: 'no',
    title: 'No.',
    dataIndex: 'no',
    render: functions.renderTooltip,
    width: config.env.production ? '120px' : '150px'
  },
  {
    key: 'billTo',
    title: 'Bill To',
    dataIndex: ['billTo', 'name'],
    render: functions.renderTooltip
  },
  {
    key: 'shipperName',
    title: 'Shipper',
    dataIndex: ['shipper', 'name'],
    render: functions.renderTooltip
  },
  {
    key: 'consigneeName',
    title: 'Consignee',
    dataIndex: ['consignee', 'name'],
    render: functions.renderTooltip
  },
  {
    key: 'shortCntr',
    title: 'ShortCntr',
    render: functions.shortCntr,
    width: '140px'
  },
  {
    key: 'etaEtd',
    title: 'ESTPick/ESTDrop',
    render: functions.etaEtd,
    width: '160px'
  },
  {
    key: 'createdAt',
    title: 'Created',
    dataIndex: 'createdAt',
    render: functions.date,
    width: '90px'
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: functions.bookingStatus,
    width: '50px'
  }
]

export const getColumns = () => {
  // const cols = Array.isArray(columns) ? columns : [...defaultCols]
  return defaultCols
}
