import type { Booking, Job } from '@/types/graphql'
import { JobStatus } from '@/types/graphql'

import { useEffect, useState } from 'react'

import JobDetailsDisplay from '@/components/Booking/JobsAndTrips/JobDetailsDisplay'

type JobsAndTripsProps = {
  booking: Booking
  hideDeleted?: boolean
  hideFunctions?: boolean
}

const JobsAndTrips = ({
  booking,
  hideDeleted = true,
  hideFunctions = false
}: JobsAndTripsProps) => {
  const [filteredJobs, setFilteredJobs] = useState<(Job | null)[]>([])

  useEffect(() => {
    if (!booking?.jobs) return
    if (hideDeleted)
      setFilteredJobs(booking.jobs.filter(job => job && job.status !== JobStatus.Cancelled))
    else setFilteredJobs(booking.jobs)
  }, [hideDeleted, booking.jobs])

  return (
    <>
      {filteredJobs.map(
        (job: Job | null, index) =>
          job && (
            <JobDetailsDisplay
              job={job}
              index={index}
              key={`JobsAndTrips-${job.key || job.uuid}`}
              hideDeleted={hideDeleted}
              hideFunctions={hideFunctions}
            />
          )
      )}
    </>
  )
}

export default JobsAndTrips
