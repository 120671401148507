import type { MouseEvent } from 'react'

import { memo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

const HeaderLogo = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const filter = searchParams.get('filter')

  const handleClick = (e: MouseEvent) => {
    e.preventDefault()

    if (isEmpty(filter)) {
      navigate('/')
    } else {
      // this is for when there is facet filter in URL
      // it will navigate & remove the filter state in the url
      window.location.href = '/'
    }
  }

  return (
    <div
      id="swift-logo"
      onClick={handleClick}
      className="cursor-pointer"
      aria-label="back to home page"
    >
      <picture>
        <source
          width={53}
          height={42}
          media="(max-width:1100px)"
          srcSet="/static/switftlogo_notext.png"
        />
        <img
          width={110}
          height={50}
          decoding="async"
          alt="swift haulage logo"
          src="/static/swiftLogoR.png"
        />
      </picture>
    </div>
  )
}

export default memo(HeaderLogo)
