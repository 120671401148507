import type { CollapseProps } from 'antd-v5'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Affix, Button, Collapse, Layout, Result, Typography } from 'antd-v5'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

import styles from '@/components/Booking/BookingForm.module.css'
import { getBkQueryVariables } from '@/components/Booking/bookingHelper'
import { EmptyBooking } from '@/components/Booking/Container'
import Events from '@/components/Booking/Events'
import OverviewSection from '@/components/Booking/OverviewSection'
import Status from '@/components/Booking/Status'
import Workflows from '@/components/Booking/Workflows'
import DocumentViewer from '@/components/DocumentViewer'
import VoucherModal from '@/components/Voucher/ModalView'
import { BASIC_BOOKING_SLIM_QUERY } from '@/containers/booking/schema/basicBookingQuerySlim'
import BookingContext from '@/contexts/booking'
import ErrorFallback from '@/ErrorFallback'
import { useIsMobile } from '@/hooks/use-mobile'
import { useBookingStore } from '@/store/booking'
import handleResponse from '@/utils/responseHandler'

type BookingFormProps = {
  params: { no?: string; uuid?: string }
}

const BookingForm = ({ params }: BookingFormProps) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const [refreshCount, setRefreshCount] = useState(0)

  const isBookingCollapse = useBookingStore.use.isBookingCollapse()
  const setIsBookingCollapse = useBookingStore.use.setIsBookingCollapse()

  const showVoucher = useSelector(state => state?.voucher?.showVoucher)
  const showDocumentViewer = useSelector(state => state?.bookingDocument?.showDocumentViewer)

  const { data, loading, error, ...bookingProps } = useQuery(BASIC_BOOKING_SLIM_QUERY, {
    variables: getBkQueryVariables(params),
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all'
  })

  useEffect(() => {
    setIsBookingCollapse(isMobile)
    // eslint-disable-next-line
  }, [])

  if (isEmpty(data)) {
    if (loading) {
      return (
        <EmptyBooking>
          <LoadingOutlined />
        </EmptyBooking>
      )
    }

    if (error) {
      if (error.graphQLErrors?.[0]?.message) {
        handleResponse(error.graphQLErrors?.[0]?.message, 'error')
      }
    }

    const isServerError = error?.message === 'Failed to fetch'

    if (isServerError) return <ErrorFallback />

    return (
      <Result
        title={'403'}
        status={'403'}
        style={{ height: '80vh' }}
        className="flex flex-col justify-center items-center"
        subTitle={error?.graphQLErrors?.[0]?.message}
        extra={
          <Button style={{ height: 30 }} href="/" type="primary">
            {t('error.backHome')}
          </Button>
        }
      />
    )
  }

  const refresh = () => {
    setRefreshCount(refreshCount + 1)
    bookingProps.refetch()
  }

  const toggleSideDrawer = () => setIsBookingCollapse(!isBookingCollapse)

  const createdAtDate = moment(data?.booking?.createdAt).format('DD/MM/YYYY')
  const createdAtTime = moment(data?.booking?.createdAt).format('hh:mm A')

  const panelItems: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <Typography.Text strong className={styles.collapseText}>
          STATUS
        </Typography.Text>
      ),
      children: <Status bookingState={data?.booking?.state} booking={data?.booking} />
    },
    {
      key: '2',
      label: (
        <Typography.Text strong className={styles.collapseText}>
          CREATED AT
        </Typography.Text>
      ),
      children: (
        <>
          <Typography.Text style={{ fontSize: 12 }}>
            {createdAtDate}
            <br />
          </Typography.Text>
          <Typography.Text style={{ fontSize: 12 }}>{createdAtTime}</Typography.Text>
        </>
      )
    },
    {
      key: '3',
      label: (
        <Typography.Text strong className={styles.collapseText}>
          EVENT
        </Typography.Text>
      ),
      children: <Events bookingUuid={data?.booking?.uuid} />
    }
  ]

  const bookingQuery = { booking: data.booking, loading, error, ...bookingProps }

  return (
    <BookingContext.Provider value={bookingQuery}>
      <div id="bookingOverview" className={styles.page}>
        <div className="w-full mr-2">
          <OverviewSection booking={data.booking} collapsedDrawer={isBookingCollapse} />

          <Workflows
            bookingQuery={bookingQuery}
            refreshCount={refreshCount}
            bookingUuid={data.booking?.uuid}
          />
        </div>

        <Affix>
          <Layout.Sider
            collapsible
            trigger={null}
            collapsedWidth={0}
            collapsed={isBookingCollapse}
            onCollapse={toggleSideDrawer}
          >
            {!isBookingCollapse && (
              <Collapse
                bordered
                items={panelItems}
                defaultActiveKey={['1']}
                style={{
                  width: isMobile ? '95vw' : undefined,
                  marginBottom: isMobile ? 30 : undefined
                }}
              />
            )}
          </Layout.Sider>
        </Affix>
      </div>

      {showVoucher && <VoucherModal bookingQuery={bookingQuery} />}

      {showDocumentViewer && (
        <DocumentViewer refreshBooking={refresh} bookingQuery={bookingQuery} />
      )}
    </BookingContext.Provider>
  )
}

export default BookingForm
