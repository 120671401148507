import { useState } from 'react'
import { Link } from 'react-router-dom'
import { CloseCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Col, Input, Row } from 'antd'
import { Button } from 'antd-v5'

import ImportExport from '@/components/Shared/ImportExport'
import { SearchBox } from '../../Styled'
import { ActionWrapper } from './Styled'

const TableViewActions = props => {
  const { updateSearchKeyword, updatePageSize } = props
  const [keyword, setKeyword] = useState('')

  const handleSearch = value => {
    updateSearchKeyword(value)
    updatePageSize(0)
  }

  const handleInput = value => {
    setKeyword(value)
    handleSearch(value)
  }

  const suffix = keyword ? <CloseCircleOutlined onClick={() => handleInput('')} /> : null

  return (
    <ActionWrapper>
      <Row type="flex" justify="space-between" align="middle">
        <SearchBox span={15}>
          <Input
            value={keyword}
            prefix={<SearchOutlined />}
            suffix={suffix}
            placeholder="Search a company here..."
            onChange={e => handleInput(e.target.value)}
          />
        </SearchBox>
        <Col span={3}>
          <ImportExport selectedEntity="companies" />
        </Col>
        <Col span={3}>
          <Link to="/manage/companies/create">
            <Button style={{ width: 120 }}>
              Create <PlusOutlined />
            </Button>
          </Link>
        </Col>
      </Row>
    </ActionWrapper>
  )
}

export default TableViewActions
