import type { LoggedInUserType } from '@/hocs/types/auth0'

import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Spin } from 'antd-v5'
import includes from 'lodash/includes'

import FeatureGuard from '@/components/Shared/FeatureGuard'
import useProcessPortalUser from '@/hooks/useProcessPortalUser'
import useUserStore from '@/store/user'
import auth from '@/utils/auth'
import { GroupLabel, MenuBody, MenuSelector, StyledMenu } from './Styled'

const LeftMenu = memo(() => {
  const { t } = useTranslation()
  const location = useLocation()
  const user: LoggedInUserType = auth.getProfile() || useUserStore.use.loggedInUser()
  const { loading, isPortalUser } = useProcessPortalUser(user)

  return (
    <Spin spinning={loading}>
      <StyledMenu>
        <MenuBody>
          <GroupLabel>Manage</GroupLabel>
          <Link to="/manage/portal-company">
            <MenuSelector selected={includes(location.pathname, 'manage/portal-company')}>
              Portal Companies
            </MenuSelector>
          </Link>

          {!isPortalUser && (
            <>
              <Link to="/manage/companies">
                <MenuSelector selected={includes(location.pathname, 'manage/companies')}>
                  Companies
                </MenuSelector>
              </Link>
              <Link to="/manage/employees">
                <MenuSelector selected={includes(location.pathname, 'manage/employees')}>
                  Employees
                </MenuSelector>
              </Link>
              <Link to="/manage/roles">
                <MenuSelector selected={includes(location.pathname, 'manage/roles')}>
                  Roles
                </MenuSelector>
              </Link>
              <Link to="/manage/permissions">
                <MenuSelector selected={includes(location.pathname, 'manage/permissions')}>
                  Permissions
                </MenuSelector>
              </Link>
              <Link to="/manage/feature-flags">
                <MenuSelector selected={includes(location.pathname, 'manage/feature-flags')}>
                  Feature Flags
                </MenuSelector>
              </Link>
              <Link to="/manage/base-company-settings">
                <MenuSelector
                  selected={includes(location.pathname, 'manage/base-company-settings')}
                >
                  Base Company Settings
                </MenuSelector>
              </Link>
              <Link to="/manage/user-settings">
                <MenuSelector selected={includes(location.pathname, 'manage/user-settings')}>
                  User Settings
                </MenuSelector>
              </Link>
              <Link to="/manage/counters">
                <MenuSelector selected={includes(location.pathname, 'manage/counters')}>
                  Counters
                </MenuSelector>
              </Link>
              <Link to="/manage/document-creator-templates">
                <MenuSelector
                  selected={includes(location.pathname, 'manage/document-creator-templates')}
                >
                  Document Creator Templates
                </MenuSelector>
              </Link>
              <Link to="/manage/process-flows">
                <MenuSelector selected={includes(location.pathname, 'manage/process-flows')}>
                  Process Flows
                </MenuSelector>
              </Link>
              <FeatureGuard name="DOCUMENT GENERATOR">
                <Link to="/manage/document-generator-templates">
                  <MenuSelector
                    selected={includes(location.pathname, 'manage/document-generator-templates')}
                  >
                    Document Generator
                  </MenuSelector>
                </Link>
              </FeatureGuard>
              <FeatureGuard name="PLUGINS">
                <Link to="/manage/plugins">
                  <MenuSelector>{t('common.plugins')}</MenuSelector>
                </Link>
              </FeatureGuard>

              {/** ****************** CHARGE SECTION ********************/}
              <GroupLabel>Charge</GroupLabel>
              <Link to="/manage/charge-items">
                <MenuSelector selected={includes(location.pathname, 'manage/charge-items')}>
                  Charge Items
                </MenuSelector>
              </Link>
              <Link to="/manage/charge-categories">
                <MenuSelector selected={includes(location.pathname, 'manage/charge-categories')}>
                  Charge Categories
                </MenuSelector>
              </Link>
              <Link to="/manage/quotations">
                <MenuSelector selected={includes(location.pathname, 'manage/quotations')}>
                  Quotations
                </MenuSelector>
              </Link>
              <Link to="/manage/rates">
                <MenuSelector selected={includes(location.pathname, 'manage/rates')}>
                  Rates
                </MenuSelector>
              </Link>
              <Link to="/manage/holidays">
                <MenuSelector selected={includes(location.pathname, 'manage/holidays')}>
                  Holidays
                </MenuSelector>
              </Link>

              {/** ****************** TRANSPORT SECTION ********************/}
              <>
                <GroupLabel>Transport</GroupLabel>
                <Link to="/manage/booking-types">
                  <MenuSelector selected={includes(location.pathname, 'manage/booking-types')}>
                    Booking Types
                  </MenuSelector>
                </Link>
                <>
                  <Link to="/manage/job-types">
                    <MenuSelector selected={includes(location.pathname, 'manage/job-types')}>
                      Job Types
                    </MenuSelector>
                  </Link>
                  <Link to="/manage/incentive-types">
                    <MenuSelector selected={includes(location.pathname, 'manage/incentive-types')}>
                      Incentive Types
                    </MenuSelector>
                  </Link>
                  <Link to="/manage/yards">
                    <MenuSelector selected={includes(location.pathname, 'manage/yards')}>
                      Staging Yards
                    </MenuSelector>
                  </Link>
                  <Link to="/manage/transport-area-codes">
                    <MenuSelector
                      selected={includes(location.pathname, 'manage/transport-area-codes')}
                    >
                      Transport Area Codes
                    </MenuSelector>
                  </Link>
                  <Link to="/manage/transport-zones">
                    <MenuSelector selected={includes(location.pathname, 'manage/transport-zones')}>
                      Transport Zones
                    </MenuSelector>
                  </Link>
                  <Link to="/manage/fafs">
                    <MenuSelector selected={includes(location.pathname, 'manage/fafs')}>
                      FAF Rates
                    </MenuSelector>
                  </Link>
                </>
              </>

              {/** ****************** FINANCE SECTION ********************/}
              <GroupLabel>Finance</GroupLabel>
              <Link to="/manage/taxes">
                <MenuSelector selected={includes(location.pathname, 'manage/taxes')}>
                  Taxes
                </MenuSelector>
              </Link>
              <Link to="/manage/currencies">
                <MenuSelector selected={includes(location.pathname, 'manage/currencies')}>
                  Currencies
                </MenuSelector>
              </Link>
              <Link to="/manage/exchange-rates">
                <MenuSelector selected={includes(location.pathname, 'manage/exchange-rates')}>
                  Exchange Rates
                </MenuSelector>
              </Link>
              <Link to="/manage/glcodes">
                <MenuSelector selected={includes(location.pathname, 'manage/glcodes')}>
                  GL Codes
                </MenuSelector>
              </Link>
              <Link to="/manage/billing-units">
                <MenuSelector selected={includes(location.pathname, 'manage/billing-units')}>
                  Billing Units
                </MenuSelector>
              </Link>

              {/** ****************** INTEGRATE SECTION ********************/}
              <GroupLabel>Integrate</GroupLabel>
              <Link to="/manage/integration-mappings">
                <MenuSelector selected={includes(location.pathname, 'manage/integration-mappings')}>
                  Mappings
                </MenuSelector>
              </Link>
              <Link to="/manage/integration-details">
                <MenuSelector selected={includes(location.pathname, 'manage/integration-details')}>
                  Details
                </MenuSelector>
              </Link>
              <Link to="/manage/integration-logs">
                <MenuSelector selected={includes(location.pathname, 'manage/integration-logs')}>
                  Logs
                </MenuSelector>
              </Link>
              <Link to="/manage/integration-bulk-import-functions">
                <MenuSelector
                  selected={includes(location.pathname, 'manage/integration-bulk-import-functions')}
                >
                  Bulk Import Functions
                </MenuSelector>
              </Link>

              <GroupLabel>Logs</GroupLabel>
              <Link to="/manage/logs/vouchers">
                <MenuSelector selected={includes(location.pathname, 'manage/logs/vouchers')}>
                  Vouchers
                </MenuSelector>
              </Link>
              <Link to="/manage/logs/etc">
                <MenuSelector selected={includes(location.pathname, 'manage/logs/etc')}>
                  Etc
                </MenuSelector>
              </Link>
            </>
          )}
        </MenuBody>
      </StyledMenu>
    </Spin>
  )
})

export default LeftMenu
