import type { TransportJob } from '@/types/graphql'

import pick from 'lodash/pick'
import moment from 'moment'
import uuidV4 from 'uuid/v4'

export const extractLegUpdateFormValues = (leg: TransportJob, values: any) => {
  const fields = [
    'tripUuid',
    'legUuid',
    'transportUuid',
    'remarks',
    'driverUuid',
    'driverCode',
    'driverName',
    'driverIc',
    'driverPhoneNo',
    'assistantUuids',
    'vehicleUuid',
    'vehicleCode',
    'vehicleName',
    'trailerUuid',
    'trailerCode',
    'trailerName'
  ]

  const updateLegInput = {
    ...pick(values, fields),
    tripUuid: leg?.tripUuid,
    legUuid: leg?.legUuid,
    _id: uuidV4()
  }

  const isFirstTripAndFirstLeg = leg?.sequence === 1 && leg?.tripSequence === 1

  const updateLegTimingInput = {
    _id: uuidV4(),
    tripUuid: leg?.tripUuid,
    legUuid: leg?.legUuid,
    planStart: moment(isFirstTripAndFirstLeg ? leg.shipperRequiredDate : values.planStart).toDate()
  }

  return {
    updateLegInput,
    updateLegTimingInput
  }
}
