/* eslint-disable react-hooks/exhaustive-deps */
import type { Driver } from '@/types/graphql'
import { DriverStatus } from '@/types/graphql'

import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import { isObject } from 'lodash'
import debounce from 'lodash/debounce'

import { DRIVERS_QUERY } from '@/components/Transport/Schemas/schema'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

const { Option } = Select

type DriverSelectorProps = {
  mode?: string | undefined | null
  onChange?: any
  statuses?: Array<DriverStatus>
  isUpdate?: boolean | undefined | null
  setVehiclePreference?: any
  style?: any
  value?: any
  queryOnMount?: boolean
  returnObject?: boolean
}

const DriverSelector = forwardRef((props: DriverSelectorProps, ref: any) => {
  const { t } = useTranslation()
  const {
    mode,
    style,
    value,
    onChange,
    isUpdate,
    queryOnMount = false,
    setVehiclePreference,
    statuses = [DriverStatus.Active],
    returnObject = false
  } = props

  const [drivers, setDrivers] = useState<Driver[]>([])
  const [searchInput, setSearchInput] = useState('')

  const getInputValue = useMemo(() => {
    // @ts-ignore
    return isObject(value) ? value?.uuid : value || searchInput || undefined
  }, [value, searchInput])

  const input = useMemo(() => {
    return {
      q: (!mode && getInputValue) || searchInput || '',
      statuses,
      uuids: (mode && !searchInput && value) || [],
      limit: 20
    }
  }, [mode, getInputValue, searchInput, statuses])

  const [getDrivers, { error, loading }] = useLazyQuery(DRIVERS_QUERY, {
    variables: { ...input },
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      const rows = data?.drivers?.rows
      if (rows?.length) {
        setDrivers(rows)
        if (rows.length === 1) {
          handleChange(returnObject ? rows[0] : rows[0].uuid)
        }
      }
    }
  })

  useEffect(() => {
    if (queryOnMount || value) {
      getDrivers()
    }
  }, [queryOnMount, value])

  const handleSearch = useCallback(
    debounce((value: string) => {
      try {
        setSearchInput(value)
        getDrivers()
      } catch (error) {
        // ok to fail when the component has already unmounted
      }
    }, 500),
    []
  )

  const onSelect = useCallback(
    (onSelectVal: string) => {
      const selectedDriver = drivers?.find((d: any) => d?.uuid === onSelectVal)
      if (selectedDriver?.vehiclePreference?.length && setVehiclePreference) {
        setVehiclePreference(selectedDriver.vehiclePreference)
      }
    },
    [drivers, setVehiclePreference]
  )

  if (error) {
    logger.error('DriverSelector useQuery error', error)
    responseHandler(error, 'error')
  }

  const fetchOnFocus = () => {
    if (queryOnMount) return

    getDrivers()
  }

  const handleChange = useCallback(
    (selectedValue: any) => {
      if (!returnObject) {
        onChange?.(selectedValue)
        return
      }

      if (!selectedValue) {
        onChange?.(null)
        return
      }

      const selectedDriver = drivers?.find((d: any) => d?.uuid === selectedValue)
      onChange?.(selectedDriver || selectedValue)
    },
    [onChange, returnObject, drivers]
  )

  return (
    <Select
      id="driver-leg-form-selector"
      ref={ref}
      allowClear
      showSearch
      // @ts-ignore
      mode={mode}
      style={{ width: '100%', ...style }}
      loading={loading}
      onChange={handleChange}
      onSelect={onSelect}
      filterOption={false}
      disabled={!!isUpdate}
      onFocus={fetchOnFocus}
      onSearch={handleSearch}
      placeholder={t('common.searchADriver')}
      value={getInputValue || undefined}
      notFoundContent={loading ? t('common.searching') : t('common.notFound')}
    >
      {drivers?.map((driver: Driver) => (
        <Option key={driver.uuid} value={driver.uuid}>
          {driver.code} - {driver.name}
        </Option>
      ))}
    </Select>
  )
})

export default DriverSelector
