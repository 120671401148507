import { useState } from 'react'
import Dropzone from 'react-dropzone'
import { InboxOutlined, PaperClipOutlined } from '@ant-design/icons'
import { Typography } from 'antd-v5'

import styles from '@/components/Booking/DynamicField/UploadDocument/UploadDocument.module.css'
import WithUpload from '@/hocs/upload'
import responseHandler from '@/utils/responseHandler'

type UploadDocumentProps = {
  data?: File[]
  label?: string
  fieldKey: string
  isUploadable?: boolean
  setFieldsValue?: (values: { [key: string]: File[] }) => void
  handleDocumentUpload?: (acceptedFiles: File[], rejectedFiles: File[]) => void
}

function UploadDocument({
  label,
  data = [],
  fieldKey,
  setFieldsValue,
  isUploadable = true,
  handleDocumentUpload
}: UploadDocumentProps) {
  const [files, setFiles] = useState<File[]>(data)

  const handleSelectFiles = (acceptedFiles, rejectedFiles) => {
    handleDocumentUpload?.(acceptedFiles, rejectedFiles)
    const duplicateFiles = files.filter(f => acceptedFiles.some(af => af.name === f.name))
    if (duplicateFiles.length > 0) {
      return responseHandler('Duplicate files are not allowed', 'error')
    }
    const newFiles = [...files, ...acceptedFiles]
    setFiles(newFiles)
    setFieldsValue?.({ [fieldKey]: newFiles })
  }

  const onRemoveFiles = (file: File) => {
    const newFiles = files.filter(f => f.name !== file.name)
    setFiles(newFiles)
    setFieldsValue?.({ [fieldKey]: newFiles })
  }

  return (
    <div className={styles.container}>
      {isUploadable && (
        <Dropzone id="drop-document-field" className={styles.dropzone} onDrop={handleSelectFiles}>
          <InboxOutlined className={styles.icon} />
          {label && <div className={styles.label}>{label}</div>}
        </Dropzone>
      )}

      <div className={styles.docContainer}>
        {files.length > 0
          ? files.map(doc => (
              <div
                key={doc.name}
                className={styles.doc}
                style={{ cursor: !isUploadable ? undefined : 'pointer' }}
                onClick={() => (!isUploadable ? undefined : onRemoveFiles(doc))}
              >
                <PaperClipOutlined className={styles.docIcon} />
                <Typography.Text className={styles.docText}>{doc.name}</Typography.Text>
              </div>
            ))
          : null}
      </div>
    </div>
  )
}

export default WithUpload(UploadDocument)
