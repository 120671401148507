import type { TransportJob } from '@/types/graphql'
import type { FormComponentProps } from 'antd/lib/form'

import { memo, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Form } from 'antd'
import { Modal, Typography } from 'antd-v5'
import cloneDeep from 'lodash/cloneDeep'

import LegTable from '@/components/Transport/Components/Modals/JobActivityModal/LegTable'
import UpdateJobForm from '@/components/Transport/Components/Modals/JobActivityModal/StatusForm'
import { TRIP_AND_LEGS_QUERY } from '@/components/Transport/Schemas/schema'
import { BASIC_BOOKING_SLIM_QUERY } from '@/containers/booking/schema/basicBookingQuerySlim'
import { UPDATE_JOB } from '@/graphql/booking'
import { logger } from '@/utils/logger'
import respHandler from '@/utils/responseHandler'
import { isUuid } from '@/utils/u'

export type UpdateJobDataType = {
  uuid?: string
  no?: string
}

interface JobActivityModalProps extends FormComponentProps {
  vehicleCodeOrReg?: string
  setRefetchTrips?: any
  leg?: TransportJob
  visible?: boolean
  onCancel?: any
}

const JobActivityModal = (props: JobActivityModalProps) => {
  const { leg, form, visible, onCancel, setRefetchTrips, vehicleCodeOrReg } = props
  const [refetchLoading, setRefetchLoading] = useState(false)

  const params = useParams()

  const {
    loading: tripLoading,
    data: job,
    error: tripErr,
    refetch: refetchTrip
  } = useQuery(TRIP_AND_LEGS_QUERY, {
    variables: {
      input: {
        limit: 100,
        tripUuids: [leg?.tripUuid]
      }
    },
    fetchPolicy: 'cache-and-network'
  })

  const [updateJobNo, { loading: updateJobLoading }] = useMutation(UPDATE_JOB)

  const { refetch } = useQuery(BASIC_BOOKING_SLIM_QUERY, {
    variables: { no: params?.no }
  })

  const refetchLegsDelayed = useCallback(async () => {
    setRefetchLoading(true)
    if (setRefetchTrips) {
      setRefetchTrips(true)
    }

    setTimeout(async () => {
      try {
        if (leg?.tripUuid && isUuid(leg?.tripUuid) && refetchTrip) {
          await refetchTrip()
        }
        if (setRefetchLoading) {
          setRefetchLoading(false)
        }
      } catch (error) {
        console.error('JobActivityModal refetchLegsDelayed error', error)
        if (setRefetchLoading) {
          setRefetchLoading(false)
        }
      }
    }, 1500)
  }, [leg?.tripUuid, refetchTrip, setRefetchTrips])

  const handleModalOk = useCallback(() => {
    form.validateFields(async (err: any, values: any) => {
      if (err) {
        return
      }

      try {
        const updateJobVals: UpdateJobDataType = {
          uuid: leg?.jobUuid,
          no: values?.no
        }

        const res = await updateJobNo({ variables: { input: updateJobVals } })

        if (res?.data?.updateJob?.uuid) {
          respHandler(`Successfully updated job ${leg?.jobNo}.`, 'success')
          refetchLegsDelayed()
        }
      } catch (error) {
        logger.error('JobActivityModal updateJobNo error', error)
        respHandler(error, 'error')
      }
    })
  }, [form, refetchLegsDelayed, leg, updateJobNo])

  const sortedLegs = useMemo(
    () => cloneDeep(job?.transportJobs?.rows)?.sort((a: any, b: any) => a.sequence - b.sequence),
    [job]
  )

  if (tripErr) {
    logger.error('JobActivityModal refetchTrip error', tripErr)
    respHandler(tripErr, 'error')
  }

  const findLeg = sortedLegs?.find((l: TransportJob) => l.legUuid === leg?.legUuid)

  return (
    <Modal
      width="97%"
      open={!!visible}
      onCancel={() => {
        onCancel()
        if (params?.no) refetch()
      }}
      onOk={handleModalOk}
      style={{ top: 20 }}
      title={
        <Typography.Text strong>
          Update Legs for Trip{' '}
          {`(${leg?.tripType}${leg?.tripSequence ? `-${leg?.tripSequence}` : ''}${leg?.tripStatus ? `-${leg?.tripStatus}` : ''})`}
        </Typography.Text>
      }
      footer={null}
      closeIcon={<CloseOutlined style={{ position: 'absolute', right: 7, top: 7 }} />}
    >
      <>
        <UpdateJobForm
          form={form}
          trip={sortedLegs}
          leg={findLeg || leg}
          handleModalOk={handleModalOk}
          updateJobLoading={updateJobLoading}
        />
        <LegTable
          legs={sortedLegs || [leg]}
          refetchLegs={refetchLegsDelayed}
          vehicleCodeOrReg={vehicleCodeOrReg}
          legsLoading={tripLoading || refetchLoading}
        />
      </>
    </Modal>
  )
}

export default Form.create<JobActivityModalProps>()(memo(JobActivityModal))
