import type { Booking } from '@/types/graphql'
import type { PaginationProps, TableProps } from 'antd-v5'

import { memo } from 'react'
import { Table, Typography } from 'antd-v5'

import { useBookingStore } from '@/store/booking'

const { Text } = Typography

type BookingsListViewProps = {
  total: number
  columns: TableProps<Booking>['columns']
  loading: boolean
  results: Booking[]
  pageSize: number
  currentPage: number
  handlePageChange: (page: number, pageSize?: number) => void
  handleSizeChange: (current: number, size: number) => void
}

const BookingsListView = (props: BookingsListViewProps) => {
  const {
    total,
    columns,
    loading,
    results,
    pageSize,
    currentPage,
    handlePageChange,
    handleSizeChange
  } = props

  const selectedBookingsTable = useBookingStore.use.selectedBookingsTable()
  const setSelectedBookingsTable = useBookingStore.use.setSelectedBookingsTable()

  const pagination: PaginationProps = {
    total,
    showTotal: (total, range) => (
      <Text strong type="secondary">{`${range[0]}-${range[1]} of ${total} bookings`}</Text>
    ),
    showSizeChanger: true,
    pageSize: pageSize || 10,
    current: currentPage + 1,
    onChange: handlePageChange,
    onShowSizeChange: handleSizeChange,
    pageSizeOptions: ['10', '20', '50', '100', '500', '1000']
  }

  const components = {
    header: {
      cell: (props: any) => (
        <th
          {...props}
          style={{
            fontSize: '12px',
            color: 'gray',
            ...props.style
          }}
        />
      )
    }
  }

  return (
    <Table
      scroll={{ x: 'max-content' }}
      className="overflow-x-scroll m-0 p-0"
      size="small"
      loading={loading}
      columns={columns}
      dataSource={results}
      pagination={pagination}
      rowKey={(record: Booking) => record.no || record.uuid}
      components={components}
      onRow={(record, rowIndex) => {
        return {
          style: {
            cursor: 'pointer',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          },
          id: `booking-row-${rowIndex}`,
          onClick: e => {
            const url = `/bookings/${record.no}`
            if (e.ctrlKey || e.metaKey || e.button === 1) {
              window.open(url, '_blank')
            } else {
              window.location.href = url
            }
          }
        }
      }}
      rowSelection={{
        selectedRowKeys: selectedBookingsTable,
        onChange: selectedRowKeys => {
          setSelectedBookingsTable(selectedRowKeys as string[])
        }
      }}
    />
  )
}

export default memo(BookingsListView)
