import type { GeneratedDocumentQuery } from '@/types/graphql'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Divider, Flex } from 'antd-v5'

import AssociatedBookingsTable from '@/components/Booking/General/DocumentGeneratorSection/TableView/EditAction/DocumentCreatorModal/ModalContent/AssociatedBookingsTable'
import DataForm from '@/components/Booking/General/DocumentGeneratorSection/TableView/EditAction/DocumentCreatorModal/ModalContent/DataForm'
import DocumentViewer from '@/components/Booking/General/DocumentGeneratorSection/TableView/EditAction/DocumentCreatorModal/ModalContent/DocumentViewer'
import { useBookingStore } from '@/store/booking'

interface ModalContentProps {
  generatedDocument: GeneratedDocumentQuery['generatedDocument']
}

const ModalContent = (props: ModalContentProps) => {
  const { generatedDocument } = props

  const { t } = useTranslation()
  const [renderRaw, setRenderRaw] = useState(false)

  const setDocumentGeneratorModalGeneratedDocument =
    useBookingStore.use.setDocumentGeneratorModalGeneratedDocument()
  const resetDocumentGeneratorModalGeneratedDocument =
    useBookingStore.use.resetDocumentGeneratorModalGeneratedDocument()

  useEffect(() => {
    setDocumentGeneratorModalGeneratedDocument(generatedDocument)
    return () => {
      resetDocumentGeneratorModalGeneratedDocument()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Flex vertical>
        <AssociatedBookingsTable generatedDocument={generatedDocument} />
      </Flex>

      <Divider>Editor</Divider>

      <Flex gap={'middle'}>
        <Flex vertical flex={0.6}>
          <Flex justify="space-between" align="center">
            <h3>{t('booking.documentGenerator.document')}</h3>
            <Checkbox checked={renderRaw} onChange={() => setRenderRaw(!renderRaw)}>
              Raw Preview
            </Checkbox>
          </Flex>

          <DocumentViewer generatedDocument={generatedDocument} renderRaw={renderRaw} />
        </Flex>

        <Flex vertical flex={0.4}>
          <h3>{t('booking.documentGenerator.data')}</h3>
          <DataForm generatedDocument={generatedDocument} />
        </Flex>
      </Flex>
    </>
  )
}

export default ModalContent
