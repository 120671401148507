import { DocumentStatus } from '@/types/graphql'

import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { EllipsisOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Modal, Popover } from 'antd'

import { getBkQueryVariables } from '@/components/Booking/bookingHelper'
import { DELETE_BOOKING_DOCUMENT } from '@/components/DocumentViewer/Content/schema'
import { StyledMenuItem } from '@/components/DocumentViewer/Header/Styled'
import EmptyAnchor from '@/components/EmptyAnchor'
import BookingDocumentQuery from '@/components/Workflow/Uploaded/UploadedItems/BookingDocumentQuery'
import schema from '@/containers/booking/schema'
import * as bookingDocumentActions from '@/states/reducers/bookingDocument'

const { confirm } = Modal

const HeaderOptions = ({ bookingDocument, toggleDocumentViewer, refreshBooking }) => {
  const dispatch = useDispatch()
  const { params } = useParams()

  const [visible, setVisible] = useState(false)

  const [deleteDocument] = useMutation(DELETE_BOOKING_DOCUMENT, {
    refetchQueries: [
      {
        query: schema.BOOKING_QUERY,
        variables: getBkQueryVariables(params)
      },
      {
        query: BookingDocumentQuery,
        variables: { uuid: bookingDocument.uuid }
      }
    ],
    onCompleted: result => handleDeleted(result)
  })

  const handleDelete = () => {
    setVisible(false)
    confirm({
      title: 'Confirm to delete this document?',
      content: 'All changes or links to this document will be gone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deleteDocument({ variables: { uuid: bookingDocument.uuid } })
      }
    })
  }

  const handleDeleted = ({ deleteBookingDocument }) => {
    dispatch(
      bookingDocumentActions.updateSelectedBookingDocument({
        ...deleteBookingDocument,
        allowedApprovals: bookingDocument.allowedApprovals
      })
    )

    refreshBooking()
    toggleDocumentViewer()
  }

  const isPdfType = bookingDocument.document.type === 'application/pdf'

  const Content = () => (
    <>
      <StyledMenuItem target="_blank" href={`/booking/document/${bookingDocument.uuid}`}>
        View in new tab
      </StyledMenuItem>
      {isPdfType && (
        <StyledMenuItem target="_blank" href={`/booking/document/${bookingDocument.uuid}?tag=1`}>
          View Tagged in new tab
        </StyledMenuItem>
      )}
      {bookingDocument.status !== DocumentStatus.Deleted && (
        <StyledMenuItem onClick={handleDelete}>Delete document</StyledMenuItem>
      )}
    </>
  )

  return (
    <Popover content={<Content />} placement="bottomLeft" trigger="click" visible={visible}>
      <EmptyAnchor>
        <EllipsisOutlined className="pl-0" onClick={() => setVisible(!visible)} />
      </EmptyAnchor>
    </Popover>
  )
}

export default HeaderOptions
