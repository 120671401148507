import type { Job } from '@/types/graphql'

import { Fragment, memo, useCallback, useEffect } from 'react'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Flex, Typography } from 'antd-v5'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuidv4 } from 'uuid'

import DuplicateButton from '@/components/Booking/NewBookingForm/TransportDetailsSubForm/JobsForm/DuplicateButton'
import JobForm from '@/components/Booking/NewBookingForm/TransportDetailsSubForm/JobsForm/JobForm'

interface JobsFormProps {
  value: any
  onChange?: (jobs: Job[]) => void
}

const JobsForm = ({ value, onChange = () => {} }: JobsFormProps) => {
  const handleAddNew = useCallback(() => {
    const newValue = [
      ...value,
      {
        details: {},
        key: uuidv4(),
        trips: [{ companyUuid: undefined, addressUuid: undefined, key: uuidv4() }]
      }
    ]
    onChange(newValue)
  }, [onChange, value])

  const handleDuplicate = useCallback(
    (index, numberOfDuplicate = 1) => {
      const newTrips = value?.[index].trips.map(trip => ({ ...trip, uuid: uuidv4() }))

      const duplicate = {
        key: uuidv4(),
        no: value[index].no,
        type: value[index].type,
        remarks: value[index].remarks,
        trips: cloneDeep(newTrips),
        details: cloneDeep(value[index].details),
        tripFormat: value[index].tripFormat
      }

      let newValue = [...value]

      for (let i = 0; i < numberOfDuplicate; i++) {
        newValue = [...newValue, duplicate]
      }

      onChange(newValue)
    },
    [onChange, value]
  )

  const handleRemove = useCallback(
    index => {
      if (value.length === 1) {
        return
      }
      const removeJob = value.filter((j, jobIndex) => jobIndex !== index)
      onChange(removeJob)
    },
    [onChange, value]
  )

  useEffect(() => {
    const withPoints = value?.map(v => {
      return {
        ...v,
        trips: v.trips?.length
          ? v.trips
          : [{ companyUuid: undefined, addressUuid: undefined, key: uuidv4() }]
      }
    })
    onChange(withPoints)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange])

  return (
    <Flex vertical gap="large">
      <Divider>{`Total Jobs : ${value?.length}`}</Divider>

      {value?.map((val, index, valArray) => {
        if (val.details) delete val.details.__typename
        val.key = uuidv4()

        return (
          <Fragment key={val.key || uuidv4()}>
            <Divider>{`Job #${index + 1}`}</Divider>

            {/* Job Forms */}
            <JobForm
              key={val.key}
              currentJob={val}
              jobIndex={index}
              allJobs={valArray}
              isNewBooking
            />

            {/* Duplicate/Add/Remove Job Buttons */}
            <Flex gap={'small'} justify="flex-end" align="center">
              <Typography.Text style={{ fontSize: '0.8em', opacity: 0.6 }}>Job:</Typography.Text>

              <DuplicateButton handleDuplicate={handleDuplicate} index={index} />

              {index === value.length - 1 && (
                <Button icon={<PlusOutlined />} onClick={handleAddNew} />
              )}

              {value.length !== 1 && (
                <Button
                  type="dashed"
                  icon={<MinusOutlined />}
                  onClick={() => handleRemove(index)}
                />
              )}
            </Flex>
          </Fragment>
        )
      })}
    </Flex>
  )
}

export default memo(JobsForm)
