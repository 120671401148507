import SearchResult from '@/components/Booking/SearchResult'
import Fallback from '@/components/Spinner/Fallback'
import base from '@/hocs/base'
import useBootstrap from '@/hooks/useBootstrap'
import { handleResponse } from '@/utils/booking'

const HomePage = props => {
  const { loading, error } = useBootstrap(props.loggedInUser.sub)

  if (loading) return <Fallback />

  if (error) {
    handleResponse(error)
  }

  return <SearchResult />
}

export default base(HomePage)
