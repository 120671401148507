import type { DynamicField } from '@/types/graphql'
import type { SelectProps } from 'antd/es/select'
import type { ModeOption } from 'antd/lib/select'
import { DynamicFieldQuery } from '@/types/graphql'

import { useState } from 'react'
import { Select } from 'antd'

import { getSelectQueryPropsForDynamicField } from '@/components/Booking/DynamicField/helper'
import SelectWithQuery from '@/components/Shared/SelectWithQuery'

interface DynamicFieldSelectorProps extends SelectProps {
  dynamicField: DynamicField
  autoSelectFirst?: boolean
}

const parseValue = ({ value, multiple }: { value: any; multiple: boolean }) => {
  let v = value
  if (!v && multiple) {
    v = []
  }
  return v
}

const parseOptions = (df: DynamicField) =>
  df.query === DynamicFieldQuery.CustomValues ? df.customValues?.split('\n') || [] : undefined

const parseMode = (df: DynamicField): ModeOption =>
  df.query === DynamicFieldQuery.CustomValues && !df.customValues
    ? 'tags'
    : df.multiple
      ? 'multiple'
      : 'default'

const DynamicFieldSelector = ({ dynamicField, ...props }: DynamicFieldSelectorProps) => {
  const [query] = useState(
    getSelectQueryPropsForDynamicField(dynamicField.query, dynamicField.companyTypes)
  )
  const [options] = useState<any[] | undefined>(parseOptions(dynamicField))

  const sharedProps = {
    ...props,
    mode: parseMode(dynamicField),
    value: parseValue({ value: props.value, multiple: !!dynamicField.multiple })
  }

  if (!dynamicField) return null

  return (
    <>
      {options?.length ? (
        <Select {...sharedProps}>
          {options.map(option => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      ) : query ? (
        <SelectWithQuery {...query} {...sharedProps} />
      ) : null}
    </>
  )
}

export default DynamicFieldSelector
