import type { DynamicField } from '@/types/graphql'
import {
  DynamicFieldControl,
  DynamicFieldQuery,
  DynamicFieldTag,
  DynamicFieldType
} from '@/types/graphql'

import { forwardRef, useEffect, useState } from 'react'
import { DatePicker, Input } from 'antd'

import DynamicInputText from '@/components/Booking/DynamicField/DynamicInputText'
import DynamicFieldSelector from '@/components/Booking/DynamicField/FieldSelector'
import {
  DYNAMIC_FIELD_DATE_FORMAT,
  getDynamicFieldInitialValue,
  getDynamicFieldLookupKey,
  getSelectQueryPropsForDynamicField,
  hasDynamicFieldTag
} from '@/components/Booking/DynamicField/helper'
import PlaceSelector from '@/components/Booking/DynamicField/PlaceSelector'
import PortPlaceSelector from '@/components/Booking/DynamicField/PortPlaceSelector'
import UploadDocument from '@/components/Booking/DynamicField/UploadDocument'
import { FormMode } from '@/components/Manage/Shared/CrudType/Form'
import EnumSelector from '@/components/Shared/EnumSelector'
import TagsInput from '@/components/Shared/TagsInput/TagsInput'

type BookingDynamicFieldProps = {
  form: any
  mode: FormMode
  dynamicField: DynamicField
  duplicateBookingObject?: any
  emptyDate?: boolean
}

const BookingDynamicField = forwardRef((props: BookingDynamicFieldProps, ref) => {
  const { form, duplicateBookingObject, dynamicField, mode, emptyDate } = props

  const [query, setQuery] = useState(
    getSelectQueryPropsForDynamicField(dynamicField.query, dynamicField.companyTypes)
  )

  useEffect(() => {
    if (dynamicField.query !== DynamicFieldQuery.Quotation) return

    const billToUuid = form.getFieldValue('billToUuid')

    if (!billToUuid) return

    setQuery(prev => ({ ...prev, variables: { ...prev.variables, buyerUuid: billToUuid } }))
  }, [form, dynamicField])

  const renderField = (props: DynamicField) => {
    const { key, query: queryType, type, customValues, tags } = props

    const defaultRule = {
      required: dynamicField.control === DynamicFieldControl.Required,
      message: `${key} is required`
    }

    const fieldKey = getDynamicFieldLookupKey(key || '', mode, type, duplicateBookingObject)

    switch (type) {
      case DynamicFieldType.Selector:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode
          )
        })(
          (() => {
            switch (queryType) {
              case DynamicFieldQuery.Place:
                return <PlaceSelector />
              case DynamicFieldQuery.Port:
                return <PortPlaceSelector mode={mode} />
              case DynamicFieldQuery.Enums:
                return (
                  <EnumSelector
                    placeholder="Select a value..."
                    enumName={dynamicField.enumName as string}
                    multiple={hasDynamicFieldTag(dynamicField, DynamicFieldTag.Multiple) ?? false}
                  />
                )
              default: {
                if (queryType === DynamicFieldQuery.CustomValues && !customValues) {
                  return <TagsInput placeholder={`Enter ${key}`} />
                }

                return (
                  <DynamicFieldSelector
                    dynamicField={dynamicField}
                    autoSelectFirst={queryType === DynamicFieldQuery.Quotation}
                  />
                )
              }
            }
          })()
        )
      case DynamicFieldType.Date:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode,
            emptyDate
          )
        })(
          <DatePicker
            id="dynamic-field-date-picker"
            showTime
            format={DYNAMIC_FIELD_DATE_FORMAT}
            disabled={mode === FormMode.Edit}
            placeholder="Select a date and time"
            // @ts-ignore
            getCalendarContainer={trigger => trigger.parentNode}
          />
        )
      case DynamicFieldType.Document:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode
          )
        })(
          <UploadDocument
            fieldKey={fieldKey}
            data={form.getFieldValue(fieldKey)}
            setFieldsValue={form.setFieldsValue}
            isUploadable={mode !== FormMode.Edit}
            handleDocumentUpload={(acceptedFiles: File) => acceptedFiles}
          />
        )

      default: {
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode
          )
        })(
          hasDynamicFieldTag(dynamicField, DynamicFieldTag.Multiline) ? (
            <Input.TextArea rows={4} autoComplete="off" placeholder={`Enter ${key}`} />
          ) : (
            <DynamicInputText
              isCapitalize={hasDynamicFieldTag(dynamicField, DynamicFieldTag.Capitalize)}
            />
          )
        )
      }
    }
  }

  return renderField(dynamicField)
})

export default BookingDynamicField
