import type { Trailer } from '@/types/graphql'
import { TrailerStatus } from '@/types/graphql'

import { forwardRef, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import { isObject } from 'lodash'
import debounce from 'lodash/debounce'

import { TRAILERS_QUERY } from '@/components/Transport/Schemas/schema'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

const { Option } = Select

type TrailerSelectorProps = {
  onChange?: any
  value?: any
  style?: React.CSSProperties
  statuses?: Array<TrailerStatus>
  returnObject?: boolean
}

const TrailerSelector = forwardRef((props: TrailerSelectorProps, ref: any) => {
  const { t } = useTranslation()

  const { value, statuses = [TrailerStatus.Active], onChange, style, returnObject = false } = props

  const [trailers, setTrailers] = useState<Trailer[]>([])
  const [searchInput, setSearchInput] = useState<string>('')

  const getInputValue = useMemo(() => {
    // @ts-ignore
    return isObject(value) ? value?.uuid : value || searchInput || undefined
  }, [value, searchInput])

  const { data, error, loading, refetch } = useQuery(TRAILERS_QUERY, {
    variables: {
      q: getInputValue || '',
      statuses,
      limit: 20
    },
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      const rows = data?.trailers?.rows
      if (rows?.length) {
        setTrailers(rows)
        if (rows.length === 1) {
          handleChange(returnObject ? rows[0] : rows[0].uuid)
        }
      }
    }
  })

  if (error) {
    logger.error('TrailerSelector TRAILERS_QUERY error', error)
    responseHandler(error, 'error')
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value: string) => {
      try {
        setSearchInput(value)
        refetch()
      } catch (error) {
        // ok to fail when the component has already unmounted
      }
    }, 500),
    [refetch]
  )

  const handleChange = useCallback(
    (selectedValue: any) => {
      if (!returnObject) {
        onChange?.(selectedValue)
        return
      }

      if (!selectedValue) {
        onChange?.(null)
        return
      }

      const selectedTrailer = trailers?.find((t: any) => t?.uuid === selectedValue)
      onChange?.(selectedTrailer || selectedValue)
    },
    [onChange, returnObject, trailers]
  )

  return (
    <Select
      id="trailer-selector"
      ref={ref}
      allowClear
      showSearch
      style={{ width: '100%', ...style }}
      loading={loading}
      onChange={handleChange}
      filterOption={false}
      onSearch={handleSearch}
      value={getInputValue || undefined}
      placeholder={t('common.selectATrailer')}
      notFoundContent={loading ? t('common.searching') : t('common.notFound')}
    >
      {trailers?.map((trailer: Trailer) => (
        <Option key={trailer.uuid} value={trailer.uuid}>
          {trailer.code} - {trailer.registration}
        </Option>
      ))}
    </Select>
  )
})

export default TrailerSelector
