import type { Booking, DynamicField as DynamicFieldType } from '@/types/graphql'
import type { WrappedFormUtils } from 'antd/lib/form/Form'
import { gql } from '@/types'
import { BookingTypeStatus, DynamicFieldControl, DynamicFieldTag } from '@/types/graphql'

import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { ErrorBoundary } from '@sentry/react'
import { Form } from 'antd'
import { Flex } from 'antd-v5'
import startCase from 'lodash/startCase'

import DynamicField from '@/components/Booking/DynamicField'
import { hasDynamicFieldTag } from '@/components/Booking/DynamicField/helper'
import { FormMode } from '@/components/Manage/Shared/CrudType/Form'
import DynamicTransportSelect from '@/components/Select/DynamicSelector'
import CompanySelect from '@/components/Select/TypeToFetch/CompanySelect'
import { ComponentErrorFallback } from '@/ErrorFallback'
import { useBookingStore } from '@/store/booking'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const BOOKING_TYPES_QUERY = gql(`
  query bookingTypesNewBookingForm(
    $limit: Int
    $offset: Int
    $q: String
    $statuses: [BookingTypeStatus]
    $byCompany: UUID
  ) {
    bookingTypes(
      limit: $limit
      offset: $offset
      q: $q
      statuses: $statuses
      byCompany: $byCompany
    ) {
      rows {
        code
        uuid
        name
        allowedJobTypes
      }
    }
  }
`)

const VIEW_BOOKING_TYPE_GQL = gql(`
  query bookingTypeNewBooking($uuid: UUID, $code: String) {
    bookingType(uuid: $uuid, code: $code) {
      code
      status
      dynamicFields {
        key
        type
        query
        companyTypes
        control
        enumName
        customValues
        tags
      }
      allowedJobTypes
    }
  }
`)

type ShipperConsigneeSubFormProps = {
  form: WrappedFormUtils
  duplicateBookingObject?: Booking
  mode?: FormMode
}

const ShipperConsigneeSubForm = (props: ShipperConsigneeSubFormProps) => {
  const { form, duplicateBookingObject = {}, mode = FormMode.Create } = props
  const { getFieldDecorator } = form

  const [dynamicFields, setDynamicFields] = useState<DynamicFieldType[]>([])
  const [queriedBookingType, setQueriedBookingType] = useState<any>(null)
  const [initialValues, setInitialValues] = useState(duplicateBookingObject)

  const selectedBillToCompany = useBookingStore.use.selectedBillToCompany()
  const setSelectedBillToCompany = useBookingStore.use.setSelectedBillToCompany()
  const setSelectedBookingTypeCode = useBookingStore.use.setSelectedBookingTypeCode()

  const [getBookingType] = useLazyQuery(VIEW_BOOKING_TYPE_GQL, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data.bookingType?.status === BookingTypeStatus.Deleted) {
        form.setFieldsValue({ type: null })
      } else {
        setQueriedBookingType(data.bookingType)
        setDynamicFields(data.bookingType?.dynamicFields || [])
      }
    }
  })

  useEffect(() => {
    const currentBooking = form.getFieldsValue()
    if (currentBooking.type !== queriedBookingType?.code) {
      setInitialValues({ ...initialValues, type: currentBooking.type })
      getBookingType({ variables: { code: currentBooking.type } })
    }

    if (currentBooking.billToUuid) setSelectedBillToCompany(currentBooking.billToUuid)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, queriedBookingType?.code])

  useEffect(() => {
    setSelectedBookingTypeCode(form.getFieldValue('type'))
  }, [form, setSelectedBookingTypeCode])

  const companySelectHandleChange = company => {
    setSelectedBillToCompany(company)
    if (mode !== FormMode.Edit) form.setFieldsValue({ type: undefined })
  }

  return (
    <Flex justify="space-between" gap="small" wrap="wrap">
      <Flex vertical flex={0.5}>
        <Form.Item label="Billing Customer" {...formItemLayout}>
          {getFieldDecorator('billToUuid', {
            initialValue: initialValues.billTo?.uuid,
            rules: [{ required: true, message: 'Billing customer is required.' }]
          })(
            <CompanySelect
              formId="billing-customer-selector"
              quickCreate
              types={['billing']}
              accountTypes={['debtor']}
              portalCompanyQueryType="book"
              onChange={companySelectHandleChange}
            />
          )}
        </Form.Item>

        <Form.Item label="Booking Type" required {...formItemLayout}>
          {getFieldDecorator('type', {
            initialValue: initialValues?.type,
            rules: [{ required: true, message: 'Booking type is required.' }]
          })(
            <DynamicTransportSelect
              defaultActiveFirstOption
              queryOnMount
              type={'bookingTypes'}
              query={BOOKING_TYPES_QUERY}
              searchText={'Select booking type...'}
              queryVariables={{ byCompany: selectedBillToCompany }}
              disabled={mode === FormMode.Edit}
            />
          )}
        </Form.Item>
      </Flex>

      <Flex vertical flex={0.5}>
        <ErrorBoundary fallback={<ComponentErrorFallback />}>
          {dynamicFields.length > 0 &&
            dynamicFields.map((field: DynamicFieldType) =>
              hasDynamicFieldTag(field, DynamicFieldTag.HideFromNewBooking) ? null : (
                <Form.Item
                  key={field.key}
                  {...formItemLayout}
                  label={startCase(field.key || '')}
                  required={field.control === DynamicFieldControl.Required}
                >
                  <DynamicField
                    form={form}
                    mode={mode}
                    dynamicField={field}
                    duplicateBookingObject={initialValues}
                  />
                </Form.Item>
              )
            )}
        </ErrorBoundary>
      </Flex>
    </Flex>
  )
}

export default ShipperConsigneeSubForm
