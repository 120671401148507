import { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { Button, Popconfirm } from 'antd'
import { cloneDeep } from 'lodash'
import PropTypes from 'prop-types'

import { JOBS_FROM_BOOKING_SLIM_QUERY } from 'App/containers/booking/schema/basicBookingQuerySlim'
import { DELETE_JOB } from 'App/graphql/booking'
import respHandler from 'App/utils/responseHandler'

class JobDeleteAction extends Component {
  handleConfirm = () => async () => {
    const {
      deleteJob,
      job: { uuid }
    } = this.props

    respHandler('Deleting job, hang on...', 'load')

    try {
      await deleteJob(uuid)

      respHandler('Job deleted successfully.', 'success')
    } catch (error) {
      console.log(error)

      respHandler(error, 'error')
    }
  }

  render() {
    return (
      <Popconfirm
        title="Confirm to delete this job?"
        onConfirm={this.handleConfirm()}
        okText="Yes"
        okType="danger"
        cancelText="No"
      >
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }
}

JobDeleteAction.propTypes = {
  job: PropTypes.object.isRequired,
  deleteJob: PropTypes.func
}

export default graphql(DELETE_JOB, {
  props: ({ mutate, ownProps }) => ({
    deleteJob: uuid =>
      mutate({
        variables: {
          uuid
        },
        update: cache => {
          const data = cache.readQuery({
            query: JOBS_FROM_BOOKING_SLIM_QUERY,
            variables: { uuid: ownProps.job.bookingUuid }
          })

          if (!data || !data.booking) return
          const { booking } = data.booking
          const clonedBooking = cloneDeep(booking)

          const jobIndex = clonedBooking.jobs.findIndex(job => job.uuid === uuid)

          clonedBooking.jobs.splice(jobIndex, 1)

          cache.writeQuery({
            query: JOBS_FROM_BOOKING_SLIM_QUERY,
            variables: { uuid: ownProps.job.bookingUuid },
            data: { booking: clonedBooking }
          })
        },
        refetchQueries: [
          {
            query: JOBS_FROM_BOOKING_SLIM_QUERY,
            variables: {
              uuid: ownProps.job.bookingUuid
            }
          }
        ]
      })
  })
})(JobDeleteAction)
